<template>
  <div>
    <v-img
      :src="require('../assets/trees-1587301_1280.jpg')"
      max-height="200"
      dark
    >
      <v-container>
        <div class="text-lg-h3 text-md-h3 text-sm-h4 text-xs-h4 mt-10">
          Baumkatalog
        </div>
        <div class="text-lg-h5 text-sm-h6">Lerne die Bäume zu erkennen!</div>
      </v-container>
    </v-img>
    <v-container>
      <v-row class="justify-center">
        <v-card
          width="300"
          v-for="tree in trees"
          :key="tree.name"
          class="mx-5 my-5"
        >
          <v-img max-height="150" :src="tree.images[0].src"> </v-img>
          <v-card-title>
            {{ tree.name | capitalized }}
          </v-card-title>
          <v-card-actions>
            <router-link
              :to="{
                name: 'Tree',
                params: { treeName: tree.name, currentIndex: 0 },
              }"
            >
              <v-btn text> Details </v-btn>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import treesData from "./trees.js";

export default {
  name: "Catalogue",
  data: () => ({
    trees: treesData,
  }),
  filters: {
    capitalized: function (treeName) {
      return treeName.charAt(0).toUpperCase() + treeName.slice(1);
    },
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used

    // all titles will be injected into this template
    title: 'Im Baumkatalog kannst du durch alle Bäume schmökern - Baumquiz',
    meta: [
      { property: "description", content: 'Der Baumkatalog ist ein Nachschlagwerk für einheimische Bäume. Er ist ideal zum Einprägen der Bäume.' },
      { property: 'og:description', content: 'Der Baumkatalog ist ein Nachschlagwerk für einheimische Bäume. Er ist ideal zum Einprägen der Bäume.' },
    ],
  },
};
</script>
